.Trackersdata-container{
    position: relative;
    margin: 0;
    padding: 0;

    width: fit-content;
    height: fit-content;
}

.Trackersdata-Rolldiceinput{
    all: unset;
    margin: 0;
    padding: 0;

    position:absolute;
    top: 16px;
    left: 15px;

    width: 138px;
    height: 1em;

    border: 0;
    background-color: var(--textbacking);
    color: var(--font-color);
}
.Trackersdata-Rolldicedata{
    margin: 0;
    padding: 0;

    position:absolute;
    top: 40px;
    left: 15px;

    width: 180px;
    height: 145px;

    font-size: .7em;
    overflow-y: scroll;
    overflow-x: hidden;

    text-align: "left";
    scrollbar-width: none;
    color: var(--font-color);
}
.Trackersdata-Rolldicedata::-webkit-scrollbar {
    display: none;
}
